<template>
  <nav class="prevnext_wrapper">
    <router-link              
      to="/"              
      class="text-body"              
    >Home
    </router-link>
    <div class="prevnext_projects">  
      <button :disabled="links.prev =='none'" class="link-button"><router-link :to="{ name: type, params: { project: links.prev }}" class="text-body">Prev</router-link></button>
      <p>/</p>         
      <button :disabled="links.next =='none'" class="link-button"><router-link 
      :to="{ name: type, params: { project: links.next }}" class="text-body">Next</router-link></button>    
    </div>    
  </nav>
</template>

<script>
import { Pages} from '@/content/index.js'
export default {
  data() {
    return {
      pages: Pages,
    }
  },
  props: {
    curProject: {
      type: String,
      required: true,
    },
  },
  computed: {
    type: function(){
      if (this.pages.portfolio.some(e => e.slug === this.curProject )) {
        return 'portfolio'        
      }else{
        return 'projects'
      }      
    },
    links: function() {      
      let cur = Pages[this.type].findIndex(e => e.slug == this.curProject);
      let prev = cur == 0 ? 'none' : Pages[this.type][cur - 1].slug,
          next = cur + 1 >= Pages[this.type].length ? 'none' : Pages[this.type][cur + 1].slug          
      return {cur, prev, next}
    },
  },
}
</script>

<style scoped>
.prevnext_wrapper{ 
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  padding-top: calc(3 * var(--padding-lg));
  padding-bottom: var(--padding-lg)   
}
.prevnext_wrapper a{
  text-decoration: none;
}
.prevnext_projects{
  display: flex;
  gap: calc(0.5 * var(--padding-sm))
}

</style>
