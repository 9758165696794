<template>
  <div class="wrapper">      
      <slot>        
        <component :is="projectComponent" />     
      </slot>
      <prev-next :curProject="project" class="page-section" />    
  </div>
  
</template>

<script>
import { defineAsyncComponent } from "vue";
import PrevNext from '@/components/PrevNext.vue'
export default {
    props:['project'],
    components: {PrevNext}, 
    computed: { 
       projectComponent() {    
            return defineAsyncComponent(() =>
            import(`@/views/work/${this.project}.vue`)
            );
        }
    },
    watch: { $route(to, from) { if(to !== from){ location.reload(); } } }
}
</script>

<style>

</style>